import React from "react";

export const UpFooter4 = () => {
  return (
    <div style={{ width: "25%" }}>
      <div className="footer-category">Community & Support</div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="https://help.uship.com/hc/en-us">
          Get Answers
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="">
          Community
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/learnmore/guaranteelearn.aspx"
        >
          uShip Guarantee
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/safeshipping/fraud_prevention.aspx"
        >
          Safe Shipping Guide
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/learnmore/newtotrucking.aspx"
        >
          New To Trucking
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://guides.uship.com/">
          uShip Guides
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/community/stories_directory.aspx"
        >
          Stories
        </a>
      </div>
      <select style={{ marginTop: "20px" }}>
        <option value="1">United States</option>
        <option value="2">Argentina</option>
        <option value="3">Australia</option>
        <option value="4">Österreich</option>
        <option value="5">Brasil</option>
        <option value="6">Canada</option>
        <option value="7">Chile</option>
        <option value="8">Colombia</option>
        <option value="9">France</option>
        <option value="10">Deutschland</option>
        <option value="11">India</option>
        <option value="12">México</option>
        <option value="13">Nederland</option>
        <option value="14">Perú</option>
        <option value="15">South Africa</option>
        <option value="16">España</option>
        <option value="17">United Kingdom</option>
        <option value="18">Venezuela</option>
        <option value="19">European Union</option>
        <option value="20">Global</option>
      </select>
    </div>
  );
};
