import React from "react";

export const UpFooter1 = () => {
  return (
    <div style={{ width: "25%" }}>
      <div className="footer-category">Getting Started</div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/shipstuff.aspx"
        >
          Shippers
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://www.uship.com/carriers">
          Carriers
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/freight-brokers"
        >
          Brokers
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/learnmore/powercarrierlearnmore.aspx"
        >
          Power Carrier
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/learnmore/powerbrokerlearnmore.aspx"
        >
          Power Broker
        </a>
      </div>
      <div className="footer-category" style={{ paddingTop: "20px" }}>
        Partnerships
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://about.uship.com/partners/">
          Partners
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://about.uship.com/affiliates/"
        >
          Affiliates
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://about.uship.com/api/">
          Developers
        </a>
      </div>
    </div>
  );
};
