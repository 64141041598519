import React from "react";
import logo from "../img/nav_main_resp.png";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <div className="topbar"></div>
        <div className="header-bg">
          <div className="header">
            <div style={{ display: "flex" }}>
              <a href="uship.com">
                <img
                  src={logo}
                  style={{ padding: "10px", marginRight: "14px" }}
                />
              </a>
              <a style={{ color: "#333333" }} href="uship.com/freight">
                <p className="header-btn">Freight</p>
              </a>
              <a style={{ color: "#333333" }} href="uship.com/householdgoods">
                <p className="header-btn">Household Goods</p>
              </a>
              <a style={{ color: "#333333" }} href="uship.com/vehicles">
                <p className="header-btn">Vehicle</p>
              </a>
              <a style={{ color: "#333333" }} href="uship.com/more">
                <p className="header-btn">More</p>
              </a>
            </div>
            <a style={{ color: "#333333" }} href="uship.com/find">
              <p className="header-btn" style={{ marginRight: 0 }}>
                Find Shipments
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
