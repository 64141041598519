import React from "react";
import { Stories } from "./Stories";
import { Gutterside } from "./Gutterside";

export const Body = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center"}}>
      <div className="main-body">
        <Stories />
        <Gutterside />
      </div>
    </div>
  );
};