import React from "react";
import earth from "../img/earth.png";
import feedbackicon from "../img/feedback.gif";
import { Comment } from "./Comment";

const comment = [
  {
    content1:
      "I sold my car to a guy that was located in another state..however I stumbled across USS AUTOMOTIVE LLC while browsing the web and am so happy I did! I got a great rate and my shipment went very smoothly and I have recommended you to all of my friends and co-workers. Thanks!",
    content2: "",
    author: "- Tom T.",
    line: true,
  },
  {
    content1:
      "Absolutely awesome. The ratings speak for themselves but I would not hesitate to recommend them.",
    content2: "",
    author: "- William S.",
    line: true,
  },
  {
    content1: "Excellent service...will use again",
    content2: "Great experience shipping my vehicle.",
    author: "- Taiye A.",
    line: true,
  },
  {
    content1:
      "Picked Up My Car In MI. on Thursday & Delivered It to the buyer In Nevada On Saterday... Great Company To Work With... Thanks USS AUTOMOTIVE LLC...",
    content2: "",
    author: "- Ken B.",
    line: true,
  },
  {
    content1: `I bought a car online. I needed to ship it from Seattle to Phoenix. USS AUTOMOTIVE LLC helped me with everything. The car was transported without any problems in a timely fashion. I was very impressed and enjoyed a hassle-free experience. As some commercials say "priceless". I would recommend both the U-ship site and USS AUTOMOTIVE LLC Logistics in the future.`,
    content2: "",
    author: "- Aaron B.",
    line: true,
  },
  {
    content1:
      "Excellent to work with. There was no guess work, no games, just a high level of customer service. Excellent communication all the way around and they delivered the vehicle promptly and with no damage or issue.",
    content2: "Would definitely use again. Absolutely A+.",
    author: "- Calvin W.",
    line: true,
  },
  {
    content1:
      "Excellent Service. The seller recommended USS AUTOMOTIVE LLC Logistics and I was a little afraid to deposit the money upfront...but everything was very professional. Prompt delivery with excellent service. Great service and Communication.",
    content2: "Outstanding job, highly recommend!",
    author: "- Gregg R.",
    line: false,
  },
];

export const Stories = () => {
  return (
    <div className="stories">
      <div className="title">uShip Stories</div>
      <div className="explain">
        Real stories by real people about their shipping experiences.
      </div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <a href="http://www.uship.com/community/stories/uship/53788/The-uShip-vehicle-shipping-advantage/">
          <div className="order">Previous Story</div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/53772/Shipped-my-Volvo-with-Care/">
          <div className="order">Next Story</div>
        </a>
        <a href="http://www.uship.com/community/stories_directory.aspx">
          <div className="order" style={{ borderRight: "none" }}>
            All Stories
          </div>
        </a>
      </div>
      <div className="summary">
        <div className="summary-title">"Highly recommended"</div>
        <div
          className="summary-title"
          style={{ fontSize: "16px", marginTop: "10px" }}
        >
          USS AUTOMOTIVE LLC
        </div>
        <div className="summary-content">
          <div className="summary-content-left">
            <h3
              style={{
                marginTop: "5px",
                padding: "5px",
                borderBottom: "1px dashed #808285",
              }}
            ></h3>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{}}>
                <img src={earth} />
              </div>
              <div className="detail">
                <div style={{ marginRight: "5px" }}>
                  <div>Company:</div>
                  <div>Entity type:</div>
                  <div>Licensed:</div>
                  <div>Website:</div>
                </div>
                <div>
                  <div>USS AUTOMOTIVE LLC</div>
                  <div>Carrier, Shipping Service</div>
                  <div>Worldwide</div>
                  <a href="https://ussautobrokers.com/">
                    <div style={{ color: "#1a63a3" }}>
                      ussautobrokers.com
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-content-right">
            <div
              style={{
                fontWeight: "bold",
                marginTop: "5px",
                fontSize: "13px",
                color: "#414042",
              }}
            >
              Service Provider:
            </div>
            <div
              style={{
                marginTop: "5px",
                fontSize: "13px",
                color: "#414042",
                textWrap: "nowrap",
                borderBottom: "1px dashed #808285",
                paddingBottom: "10px",
              }}
            >
              USS AUTOMOTIVE LLC (1427
              <img src={feedbackicon} />)
            </div>
            <div style={{ display: "flex", margin: "10px" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "125px",
                  color: "#414042",
                  lineHeight: "17px",
                }}
              >
                <div>Feedback Score:</div>
                <div>Positive Feedback:</div>
                <div>Total Positive:</div>
                <div>Total Negative:</div>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#414042",
                  lineHeight: "17px",
                }}
              >
                <div>1427</div>
                <div>100%</div>
                <div>1427</div>
                <div>0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {comment.map((value, index) => {
        return (
          <Comment
            key={index}
            content1={value.content1}
            content2={value.content2}
            author={value.author}
            line={value.line}
          />
        );
      })}
    </div>
  );
};
