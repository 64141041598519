import React from "react";

export const UpFooter3 = () => {
  return (
    <div style={{ width: "25%" }}>
      <div className="footer-category">Company</div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://about.uship.com/">
          About uShip
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://about.uship.com/press-releases/"
        >
          Press
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://blog.uship.com/">
          Blog
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://about.uship.com/highway-to-help/"
        >
          Highway to Help
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://about.uship.com/jobs/">
          Jobs
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://www.uship.com/green">
          Green Shipping
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/shippingwars/"
        >
          Shipping Wars on A&E
        </a>
      </div>
    </div>
  );
};
