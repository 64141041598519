import React from "react";
import { UpFooter1 } from "./UpFooter1";
import { UpFooter2 } from "./UpFooter2";
import { UpFooter3 } from "./UpFooter3";
import { UpFooter4 } from "./UpFooter4";

export const UpFooter = () => {
  return (
    <div className="footer">
      <UpFooter1 />
      <UpFooter2 />
      <UpFooter3 />
      <UpFooter4 />
    </div>
  );
};
