import React from "react";

export const Gutterside = () => {
  const arrow = ">";
  return (
    <div className="gutterside">
      <div style={{ fontSize: "17px", fontWeight: "bold", color: "#414042" }}>
        Ready to Ship your Stuff?
      </div>
      <div className="vehicles">
        <div style={{ fontSize: "17px", fontWeight: "bold" }}>Vehicles</div>
        <a style={{ color: "#fff" }} href="">
          <div
            style={{ fontSize: "11px", fontWeight: "bold", marginLeft: "5px" }}
          >
            Change&nbsp;{arrow}
          </div>
        </a>
        <select style={{ width: "100%", marginTop: "5px" }}>
          <option value={0}>Select a Sub-Category</option>
          <option value={1}>Trailer Homes</option>
          <option value={2}>Cars & Light Trucks</option>
          <option value={3}>Trailers</option>
          <option value={4}>Vehicle Parts</option>
          <option value={5}>Airplanes</option>
          <option value={6}>Antique Vehicles</option>
          <option value={7}>Other Vehicles</option>
          <option value={8}>Tractors</option>
          <option value={9}>Recreational Vehicles</option>
          <option value={10}>Heavy Trucks</option>
          <option value={11}>Power Sports</option>
        </select>
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "12px",
            marginTop: "5px",
            lineHeight: "17px",
          }}
        >
          Origin:
        </div>
        <div style={{ display: "flex" }}>
          <input className="input1" placeholder="ZIP/Postal"></input>
          <a
            href="http://www.uship.com/zip_lookup.aspx"
            style={{ fontSize: "11px", color: "#fff", margin: "auto 0" }}
          >
            &nbsp;Look Up
          </a>
        </div>
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "12px",
            marginTop: "5px",
            lineHeight: "17px",
          }}
        >
          Destination:
        </div>
        <div style={{ display: "flex" }}>
          <input className="input1" placeholder="ZIP/Postal"></input>
          <a
            href="http://www.uship.com/zip_lookup.aspx"
            style={{ fontSize: "11px", color: "#fff", margin: "auto 0" }}
          >
            &nbsp;Look Up
          </a>
        </div>
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "12px",
            marginTop: "5px",
            lineHeight: "17px",
          }}
        >
          Email address:
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="input2"
            placeholder="Enter your email address"
          ></input>
        </div>
        <div style={{ margin: "10px 2px 5px 2px" }}>
          <a href="https://www.autoreviews.shop/?eBay=uship&postID=53785">
            <button style={{ width: "100%" }}>Get Free Quotes Now</button>
          </a>
        </div>
      </div>
      <div className="more-title">More Vehicles Stories</div>
      <div className="more">
        <a href="http://www.uship.com/community/stories/uship/17152/Use-this-guy-for-your-transport-needs/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Use this guy for your tran...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/99041/Fast-delivery/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Fast delivery
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/47180/vehicle-shipment/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            vehicle shipment
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/77109/transport-of-vehicle/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            transport of vehicle
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/15048/Great-exp-shipping/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Great exp shipping
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/95137/Truck-Camper/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Truck Camper
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/77142/Excellent-customer-service-great-communication-tim/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Excellent customer service...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/35000/california-to-mn/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            california to mn
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/76101/Great-people-to-work-with/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Great people to work with
          </div>
        </a>
        <a href="http://www.uship.com/community/stories/uship/61044/Great-experience/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            Great experience
          </div>
        </a>
      </div>
      <div className="more-title">Read More About</div>
      <div className="more">
        <a href="http://www.uship.com/community/stories_directory_page/uship/household-goods/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Household Goods Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/business-industrial-goods/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Business & Industrial ...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/household-office-moves/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Household & Office Mov ...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/vehicles/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Vehicle Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/boats/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Boats Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/food-agricultural-goods/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Food & Agricultural Go ...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/special-care-items/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Special Care Items Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/passengers/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Passenger Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/junk/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Junk Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/pets/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Pets Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/less-than-truckload-freight/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Less Than Truckload St ...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/motorcycles-power-sports/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Motorcycles & Power Sp ...
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/heavy-equipment/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Heavy Equipment Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/small-packages/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Small Packages Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/horses-livestock/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Horses & Livestock Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/full-truckload-freight/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip FTL Freight Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/other/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip Other Stories
          </div>
        </a>
        <a href="http://www.uship.com/community/stories_directory_page/uship/ltl-freight/">
          <div style={{ paddingBottom: "7px", fontSize: "12px" }}>
            uShip LTL Freight Stories
          </div>
        </a>
      </div>
    </div>
  );
};
