import React from "react";
import ebay from "../img/ebay-compatible-transparent.png";
import terrapass from "../img/blue-badge-small.gif";
import DB from "../img/dunandbradstreetlogo.png";

export const DownFooter = () => {
  return (
    <div className="downfooter">
      <div
        style={{ width: "50%", fontSize: "11px", color: "#7D7D7D" }}
        className="footer-left"
      >
        <div style={{ height: "40px" }}></div>
        <div>
          Copyright © 2017. uShip Inc. and its licensors. All rights reserved.
        </div>
        <div style={{ display: "flex", justifyContent: "left" }}>
          <a href="http://www.uship.com/user_agreement.aspx">
            <div className="order1" style={{ paddingLeft: "0" }}>
              u Ship User Agreement
            </div>
          </a>
          <a href="http://www.uship.com/privacy.aspx">
            <div className="order1">Privacy Policy</div>
          </a>
          <a href="http://www.uship.com/sitemap.aspx">
            <div className="order1" style={{ borderRight: "none" }}>
              Site Map
            </div>
          </a>
        </div>
      </div>
      <div style={{ width: "50%" }} className="footer-right">
        <img src={ebay} style={{ paddingRight: "10px" }} />
        <div>
          <a href="http://www.terrapass.com/partners/uship/?utm_source=uship&utm_campaign=smb-partner">
            <img src={terrapass} />
          </a>
        </div>
        <a href="http://www.bbb.org/central-texas/business-reviews/internet-services/uship-in-austin-tx-90006779#sealclick">
          <div style={{ width: "52px" }}></div>
        </a>
        <div style={{}}>
          <a href="https://www.dnb.com/">
            <img src={DB} />
          </a>
        </div>
      </div>
    </div>
  );
};
