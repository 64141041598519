import React from "react";
import { Header } from "./Pages/Header";
import { Breadcrumbs } from "./Pages/Breadcrumbs";
import { Body } from "./Pages/Body";
import { Footer } from "./Pages/Footer";

const App = () => {
  return (
    <div style={{ fontFamily: "arial" }}>
      <Header />
      <Breadcrumbs />
      <Body />
      <Footer />
    </div>
  );
};

export default App;
