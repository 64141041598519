import React from "react";
import { a } from "react-router-dom";

export const Breadcrumbs = () => {
  const arrow = ">";
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="breadcrumbs">
        <div className="level">
          <a style={{ color: "#333333" }} href="uship.com">
            <div className="breadcrumb-btn">&nbsp;Home&nbsp;</div>
          </a>
          {arrow}
          <a style={{ color: "#333333" }} href="uship.com/community">
            <div className="breadcrumb-btn">&nbsp;Community&nbsp;</div>
          </a>
          {arrow}
          <a
            style={{ color: "#333333" }}
            href="uship.com/community/ushipstories"
          >
            <div className="breadcrumb-btn">&nbsp;uShip Stories&nbsp;</div>
          </a>
          {arrow}
          <a
            style={{ color: "#333333" }}
            href="uship.com/community/ushipstories/vehicles"
          >
            <div className="breadcrumb-btn">&nbsp;Vehicles&nbsp;</div>
          </a>
          {arrow}
          <a
            style={{ color: "#333333" }}
            href="uship.com/community/ushipstories/vehicles/cars&lighttrucks"
          >
            <div className="breadcrumb-btn">
              &nbsp;Cars & Light Trucks&nbsp;
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
