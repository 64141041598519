import React from "react";

export const UpFooter2 = () => {
  return (
    <div style={{ width: "25%" }}>
      <div className="footer-category">Tools</div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://www.uship.com/mobile.aspx">
          Mobile
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://www.uship.com/pro">
          uShip PRO
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/shipping-affiliate-program/"
        >
          Widgets
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://www.uship.com/tracking">
          Tracking
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/shipper_tools.aspx"
        >
          Shipping Customer Tools
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/provider_tools.aspx"
        >
          Service Provider Tools
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://reviews.uship.com/">
          Member Directory
        </a>
      </div>
      <div className="footer-content">
        <a
          style={{ color: "#a6a6a6" }}
          href="http://www.uship.com/shipping-calculator.aspx"
        >
          Shipping Calculator
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="http://about.uship.com/resources">
          Resource Center
        </a>
      </div>
      <div className="footer-content">
        <a style={{ color: "#a6a6a6" }} href="https://developer.uship.com/">
          Shipping API
        </a>
      </div>
    </div>
  );
};
