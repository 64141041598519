import React from "react";
import { UpFooter } from "./UpFooter";
import { DownFooter } from "./DownFooter";

export const Footer = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <div className="footer-bg">
          <UpFooter />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DownFooter />
        </div>
      </div>
    </div>
  );
};
