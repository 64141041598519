import React from "react";

export const Comment = ({content1, content2, author, line}) => {
  return (
    <div>
      <div className="comment-text">{content1}</div>
      <div className="comment-text">{content2}</div>
      <div className="comment-author">{author}</div>
      {line ? <div className="comment-underline"></div> : ""}
    </div>
  );
};
